<template>
	<div class="page">
		<div class="title">五险一金列表</div>
		<!-- filter -->
		<div class="filter_box">
			<div></div>
			<div class="filter_btns">
				<div class="custom_button no_select" @click="showForm()">
					自定义规则
				</div>
			</div>
		</div>

		<!-- table -->
		<el-table id="table" :data="insurancelist" :header-cell-style="tableHeaderStyle" :cell-style="tableCellStyle"
			style="width: 100%;">
			<el-table-column prop="name" label="保险名称"></el-table-column>
      <el-table-column prop="department" label="单位">
        <template slot-scope="scope">
          <span>{{scope.row.company_value?scope.row.company_value:"设置"}}%</span>
        </template>
      </el-table-column>
      <el-table-column prop="department" label="个人">
        <template slot-scope="scope">
          <span>{{scope.row.self_value?scope.row.self_value:"设置"}}%</span>
        </template>
      </el-table-column>
		</el-table>
		<el-dialog title="五险一金配置" :visible.sync="dialogFormVisible">
				<el-table :data="form">
          <el-table-column label="保险名称" prop="name"></el-table-column>
          <el-table-column label="单位">
						<template slot-scope="scope">
							<el-input v-model="scope.row.company_value"></el-input> %
						</template>
					</el-table-column>
          <el-table-column label="个人">
						<template slot-scope="scope">
							<el-input v-model="scope.row.self_value"></el-input> %
						</template>
					</el-table-column>
        </el-table>
				<!-- save -->
				<div class="custom_button no_select operation_button" @click="onSubmit">
					保存
				</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";

	export default {
		data() {
			return {
				dialogFormVisible: false,
				tableHeaderStyle: {
					backgroundColor: "#F5F8FA",
					textAlign: "center",
				},
				tableCellStyle: {
					textAlign: "center",
				},
        insurancelist: [],
        form: []
			};
		},
		created() {
			this.loadData();
		},
		methods: {
			...mapActions("pay", ["getinsurance","setinsurance"]),
			// loadData() {
			// 	let loading = this.$loading();
			// 	this.getSalarySplitRulesList({
			// 			page: this.page.page,
			// 		})
			// 		.then((res) => {
			// 			console.log(res);
			// 			res.data.data.list.map((item)=>{
			// 				let department=[]
			// 				item.department_list.map((item2)=>{
			// 					department.push(item2.name)
			// 				})
			// 				item.department = department.join(',')
			// 			})
			// 			this.tableData = res.data.data.list;
			// 			this.page.total = res.data.data.total;
			// 			loading.close();
			// 		})
			// 		.catch((e) => {
			// 			loading.close();
			// 			this.$message.error(e);
			// 		});
			// },

      loadData() {
        let loading = this.$loading();
        this.getinsurance()
          .then((res)=>{
            console.log(res)
            this.insurancelist = res.data.data
			let data = JSON.stringify(res.data.data)
			this.form = JSON.parse(data)
            loading.close();
          })
          .catch((e)=>{
            this.$message.error(e)
          })
      },

			showForm() {
				this.dialogFormVisible = true
			},

			onSubmit() {
				// 装填
				console.log(this.form);
				let data = JSON.stringify(this.form)
				this.setinsurance(data)
					.then((res)=>{
						console.log(res)
						this.$message.success(res.data.msg)
						this.dialogFormVisible = false
						this.loadData()
					})
					.catch((e)=>{
						this.$message.error(e)
					})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding-bottom: 50px;
		min-height: calc(100% - 50px);
	}

	.title {
		font-size: 14px;
		font-weight: bold;
		color: #1c2438;
	}

	.filter_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0;

		.filter_search {
			display: flex;
			align-items: center;
		}

		.filter_btns {
			display: flex;
			align-items: center;

			>div:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	::v-deep .el-form-item__label {
		font-size: 14px;
		color: #222222;
		width: 140px !important;
	}

	::v-deep .el-form-item__content {
		margin-left: 140px !important;
	}

	.el-radio.is-checked {
		::v-deep .el-radio__inner {
			border-color: #4db9d5;
			background: #4db9d5;
		}

		::v-deep .el-radio__label {
			color: #606266;
		}
	}

	.el-input {
		width: 400px;
		height: 48px;
		font-size: 14px;
		color: #222222;
	}

	// 表格内的表单大小
	.cell {
		.el-input {
			width: 100px;
			height: 40px;
			font-size: 12px;
			color: #495060;
		}

		.el-select {
			width: 100px;
			height: 40px;
			font-size: 12px;
			color: #495060;
		}

		.sp_date_picker {
			width: 130px !important;
		}
	}

	.operation_button {
		width: 142px;
		height: 42px;
		line-height: 42px;
		font-size: 14px;
		margin-top: 30px;
	}
</style>
